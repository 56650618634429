<template>
    <div v-loading="is_panding" class="computer-error-home">
        <div class="computer-error-home-title">
            <h2>לוח תקלות תפעול</h2>
            <i @click="init" class="material-icons refresh-icon" >replay</i>
        </div>
        <div class="computer-error-home-filters">
            <el-button @click="selected_status='הכל'" :type="selected_status=='הכל'?'success':'primary'">הכל</el-button>
            <el-button @click="selected_status='פתוח'"  :type="selected_status=='פתוח'?'success':'primary'" style="margin: 0 5px;">פתוח</el-button>
            <el-button @click="selected_status='בטיפול'" :type="selected_status=='בטיפול'?'success':'primary'">בטיפול</el-button>
        </div>
        <div class="computer-error-home-content">
            <h1 v-if="!filter_by_status.length" style="text-align:center; color:#333;">לא קיימות קריאות פתוחות</h1>
            <template v-for="call in filter_by_status" :key="call.uid">
                <table style="margin-bottom:5px;">
                    <tr>
                        <th style="width:30%;">תאריך פתיחה:</th>
                        <td>{{new Date(call.created_at.seconds * 1000).toLocaleString('he')}}</td>
                    </tr>
                    <tr>
                        <th style="width:30%;">תיאור התקלה</th>
                        <td>{{call.description}}</td>
                    </tr>
                    <tr>
                        <th style="width:30%;">סוג קריאה</th>
                        <td>{{call.error_type}}</td>
                    </tr>
                    <tr>
                        <th style="width:30%;">בטיפול של</th>
                        <td>{{services_people_list.size>0?services_people_list.get(call.error_type):'אנא המתן...'}}</td>
                    </tr>
                    <tr>
                        <th style="width:30%;">סטטוס</th>
                        <td><span :data-status="call.status">{{call.status}}</span></td>
                    </tr>
                    <tr>
                        <th style="width:30%;">פעולות</th>
                        <td>
                            <el-button @click="handle_cancel(call)" size="medium" type="danger">ביטול</el-button>
                            <el-button @click="handle_close(call)" size="medium" style="margin-right: 5px;" type="primary">סגירה</el-button>
                        </td>
                    </tr>
                </table>
            </template> 
        </div>
        <div :class="{'user-role':true}" class="computer-error-home-options">
            <div @click="$emit('open_call')">
                <h2>פתח קריאה</h2>
            </div>
            <!-- <div v-if="role >=99" >
                
            </div> -->
            <div v-if="role < 99" @click="$emit('open_calls_manage')">  
                <h2>הסטוריה</h2>
            </div>
            <div v-if="role >= 99" @click="$emit('open_calls_manage')">
                <h2>ניהול</h2>
            </div>
        </div>
         <!-- JUST FOR MAIL -->
        <div dir="rtl" class="form-email" id="form-email" v-show="false">
                <p style="width:100%; text-align:center; font-size:25px; margin:5px;">
                    טופס מערכת תקלות מחשוב סניף {{selected_call.branche}}
                </p>
                <table
                    dir="rtl"
                    style="width:100%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
                >
                    <tr style="background-color: #dddddd;">
                        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            נוצר בתאריך
                        </th>
                        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            {{new Date(selected_call.created_at?.seconds * 1000).toLocaleDateString('he')}}
                        </td>
                    </tr>
                    <tr>
                        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            סוג המוצר התקול
                        </th>
                        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            {{ selected_call.error_type }}
                        </td>
                    </tr>
                    <tr style="background-color: #dddddd;">
                        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            תיאור התקלה
                        </th>
                        <td style="border:1px solid #dddddd; text-align:right; padding: 8px; white-space: pre-wrap;">
                            {{ selected_call.description }}
                        </td>
                    </tr>
                    <tr>
                        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            שם פותח הקריאה
                        </th>
                        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            {{ selected_call.owner }}
                        </td>
                    </tr>
                    <tr style="background-color: #dddddd;">
                        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            טלפון נייד של פותח הקריאה  
                        </th>
                        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            {{ selected_call.phone }}
                        </td>
                    </tr>
                    <template v-for="({image_url},index) in selected_call.images" :key="image_url">
                    <tr>
                        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            תמונה {{ index+1 }}
                        </th>
                        <td style="border:1px solid #dddddd; text-align:right; padding: 8px; text-align:center;">
                            <a :href="image_url" target="_blank">
                            <img style="width:100px; height:100px;" :src="image_url">
                            </a>
                        </td>
                    </tr>
                    </template>
                </table>
        </div>
    </div>

   
    
</template>

<script>
    import {computed, onActivated, ref, watchEffect } from 'vue'
    import {get_last_open_calls,get_services_people_from_db_maped,cancel_or_close_call_in_db, get_service_person_by_error_type} from '../scripts/scripts'
    import {user,role} from '../../../../Methods/Utils'
    import {slide_pop_error,alert_confirm} from '../../../../Methods/Msgs'
    import { projectFunctions } from '../../../../firebase/config'
    
    export default {
        emits:['open_call','open_calls_manage'],
        setup(){

            const selected_call = ref({})

            const selected_status = ref('הכל')
            const is_panding = ref(false)
            const open_calls = ref([])
            const services_people_list = ref(new Map())

            const filter_by_status = computed(() => {
                if(selected_status.value=='הכל') return open_calls.value
                return open_calls.value.filter(call => call.status == selected_status.value)
            })

            const init = async() => {
                is_panding.value = true
                open_calls.value =  await get_last_open_calls()
                services_people_list.value = await get_services_people_from_db_maped()
                is_panding.value = false
            }

            const isInitialized = ref(false);
            
            const handle_cancel = (call) => {
                try{
                    console.log(call);
                    selected_call.value = call
                    alert_confirm(`לבטל את הקריאה אם התיאור הבא? ${call.description}`)
                    .then(async res => {
                        if(res.isConfirmed){
                            is_panding.value = true
                            await cancel_or_close_call_in_db('בוטל',call)
                            const res = await get_service_person_by_error_type(call.error_type)
                            await sendEmail(res?.email,'בוטלה')
                            init()
                        }
                    })
                }catch(err){
                    console.error(err.message);
                    slide_pop_error(err.message)
                }finally{
                    is_panding.value = false
                }
            }

            const handle_close = (call) => {
                try{
                    console.log(call);
                    selected_call.value = call
                    alert_confirm(`לסגור את הקריאה אם התיאור הבא? ${call.description}`)
                    .then(async res => {
                        if(res.isConfirmed){
                            is_panding.value = true
                            await cancel_or_close_call_in_db('סגור',call)
                            const res = await get_service_person_by_error_type(call.error_type)
                            await sendEmail(res?.email,'נסגרה')
                            init()
                        }
                    })
                }catch(err){
                    console.error(err.message);
                    slide_pop_error(err.message)
                }finally{
                    is_panding.value = false
                }
            }

            async function sendEmail(email,type) {
                projectFunctions.httpsCallable('send_email')({
                    html: document.getElementById("form-email").innerHTML,
                    destinations:["yavrosman@gmail.com",email||''],
                    from:"מערכת תקלות מחשוב רוסמן",
                    title:`תקלת מחשוב בסניף ${selected_call.value.branche} ב${selected_call.value.error_type} - ${type}`
                })
            }

            watchEffect(()=>{
                if(user.value && role.value>=99 && !isInitialized.value){
                    init()
                    isInitialized.value = true;
                }
            })

            onActivated(() => {
                init()
            })

            return{
                get_last_open_calls,
                is_panding,
                open_calls,
                role,
                services_people_list,
                init,
                selected_status,
                filter_by_status,
                handle_cancel,
                handle_close,
                selected_call
            }
        }
    }
</script>

<style scoped>
    .computer-error-home{
        width: 100%;
        height: 100%;
        max-width: 500px;
        margin: 0 auto;
        background: #fff;
        color: #333;
        display: flex;
        flex-direction: column;
    }
    .computer-error-home-title{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .computer-error-home-filters{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .computer-error-home-content{
        width: 100%;
        flex: 1;
        overflow-y: auto;
    }
    .computer-error-home-options{
        width: 100%;
        height: 200px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-auto-rows: calc(50% - 5px);
        padding: 5px;
        gap: 5px;
        overflow-y: auto;
        box-shadow: 0px -4px 6px -1px rgba(0, 0, 0, 0.3);
    }
    .computer-error-home-options.user-role{
        grid-template-columns: repeat(1,1fr);
        grid-auto-rows: calc(50% - 5px);
    }
    .computer-error-home-options div{
        background: var(--purple);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.1s ease-in-out;
        user-select: none;
    }
    .computer-error-home-options > div:hover{
        transform: scale(1.01);
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }

    .refresh-icon{
        color: var(--warning);
        user-select: none;
        cursor: pointer;
        font-size: 30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
    }

    [data-status="סגור"]{
        color: var(--danger) !important;
    }
    [data-status="פתוח"]{
        color: var(--success) !important;
    }
    [data-status="בטיפול"]{
        color: var(--warning) !important;
    }
</style>